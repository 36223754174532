import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/logo.svg'
import Headroom from "react-headroom"
import JSONData from "../content/info"
import phoneCall from "../img/phone-call.svg"

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
        <Headroom className="z-index-top">
          <nav
              className="navbar is-transparent navbar-shadow"
              role="navigation"
              aria-label="main-navigation"
          >
            <div className="container">
              <div className="navbar-brand">
                <Link to="/" className="navbar-item logo-item" title="Logo">
                  <img src={logo} className="nav-logo" alt="Kaldi" style={{ width: '300px', height: '100px'}} />
                </Link>
                {/* Hamburger menu */}
                <div
                    className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                    data-target="navMenu"
                    onClick={() => this.toggleHamburger()}
                >
                  <span />
                  <span />
                  <span />
                </div>
              </div>
              <div
                  id="navMenu"
                  className={`navbar-menu ${this.state.navBarActiveClass}`}
              >
                <div className="navbar-start has-text-centered">
                  <Link className="navbar-item" to="/products">
                    Oferta
                  </Link>
                  {/*
                  <Link className="navbar-item" to="/projects">
                    Projekty
                  </Link>
                  */}
                  {/* Hamburger menu
                  <Link className="navbar-item" to="/blog">
                    Aktualności
                  </Link>
                  */}
                  <Link className="navbar-item" to="/contact">
                    Kontakt
                  </Link>
                </div>
                <div className="navbar-end has-text-centered">
                  <span
                      className="navbar-item"
                  >
                    <img src={phoneCall} alt="icon" style={{ width: '28px', height: '28px'}} />
                    <span className="navbar-item">
                      {JSONData.phone}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </nav>
        </Headroom>
    )
  }
}

export default Navbar
